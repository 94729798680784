(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 896,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1366,
    "normal": 1500
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/lazyload.js');

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/tabs.js');

require('./modules/sliders.js');

require('./modules/spoilers.js');

require('./modules/compare.js');

require('./modules/filter.js');

require('./modules/history.js');

require('./modules/seen.js');

require('./modules/collections.js');

require('./modules/decorator.js');

require('./modules/print.js');

require('./modules/vuejs/card/card-app.js');

require('./modules/vuejs/order/order-app.js');

require('./modules/vuejs/calculator/calculator-app.js');

},{"./modules/collections.js":3,"./modules/compare.js":4,"./modules/decorator.js":5,"./modules/filter.js":6,"./modules/forms.js":7,"./modules/history.js":10,"./modules/lazyload.js":11,"./modules/masks.js":12,"./modules/menus.js":13,"./modules/modals.js":14,"./modules/print.js":15,"./modules/scroll.js":16,"./modules/seen.js":17,"./modules/sliders.js":18,"./modules/spinner.js":19,"./modules/spoilers.js":20,"./modules/tabs.js":21,"./modules/vuejs/calculator/calculator-app.js":22,"./modules/vuejs/card/card-app.js":23,"./modules/vuejs/order/order-app.js":30}],3:[function(require,module,exports){
'use strict';

/* global Vue */

// Collections
(function () {
  if ($('.js-collection').length) {
    $(document).on('click.collection', '.js-collection', function collectionHandler(ev) {
      ev.preventDefault();

      var $link = $(this);

      if ($link.hasClass('is-active')) return;

      var $container = $link;
      var $collection = $('#collection');
      var $otherLinks = $('.js-collection').not($link);
      var url = $link.attr('href');

      $link.addClass('is-active');
      $otherLinks.removeClass('is-active');

      if ($collection.length) {
        hideCollection($collection);

        setTimeout(function () {
          showCollection(url, $container);
        }, 600);
      } else {
        showCollection(url, $container);
      }
    });

    $(document).on('click.collection', '.js-collection-close', function (ev) {
      ev.preventDefault();

      $('#collection').slideUp(600);
      $('.js-collection').removeClass('is-active');
    });
  }

  function showCollection(url, $container) {
    $.ajax({
      method: 'POST',
      url: url,
      data: { action: 'getCollectionProducts' }
    }).done(function (chunk) {
      $('#collection').remove();
      $(chunk).hide().insertAfter($container).slideDown(600);
      $(window).scrollTo($('#collection'), 600);

      // setTimeout(() => {
      //   GLOBAL.initCarousel([{ container: '#collection-gallery', size: collectionGallerySize }])
      // }, 0);
    });
  }

  function hideCollection($collection) {
    $collection.slideUp(100, function () {
      $collection.remove();
    });
  }
})();

// Collection catalogue
// (function() {
//   if ($('.js-collection').length) {
//     $(document).on('click.collection-popup-link', '.j-catalogue-popup-link', function(ev) {
//       ev.preventDefault();
//       ev.stopPropagation();

//       const popup = $(this).data('popup');
//       const src = $(this).data('src');

//       $('#'+popup).find('iframe').attr('src', src);

//       $.overlayLoader(true, popup);
//     });
//   }
// })();


// All collections
(function () {
  if ($('#js-collections-app').length) {
    window.vm = new Vue({
      el: '#js-collections-app',

      data: {
        letter: '#',
        extra: '#',
        brandSections: jQuery()
      },

      mounted: function mounted() {
        this.brandSections = jQuery(this.$el).find('.js-brand');
      },


      methods: {
        reset: function reset() {
          this.letter = '#';
          this.extra = '#';

          this.brandSections.show();
        },
        setLetter: function setLetter(letter) {
          var _this = this;

          var extraDump = this.extra;
          this.letter = letter;

          this.extra = '#';

          setTimeout(function () {
            _this.setExtra(extraDump);
          }, 0);
        },
        setExtra: function setExtra(name) {
          var $sections = void 0;

          this.extra = name;

          if (this.letter === '#') {
            $sections = this.brandSections;
          } else {
            $sections = this.brandSections.filter('[data-letter="' + this.letter + '"]');
          }

          $sections.each(function sectionHandler() {
            var $section = $(this);

            if (name === '#' || $section.find('.icon-' + name).length) {
              $section.show();
            } else {
              $section.hide();
            }
          });
        }
      }
    });
  }
})();

// Collections page
// (function() { if (!$('.collections-in-catalog').length) return;
//   const STORAGE_KEY = 'COLLECTIONS_IN_CATALOG';

//   const store = {
//     fetch: function() {
//       const settings = JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '{}');
//       if ( Object.keys(settings).length ) {
//         settings.allVisible = true;
//         const maxSize = $(window).width() > 1500 ? 7 : 6;
//         if ( $('.collections-in-catalog .collection').length > maxSize ) {
//           settings.allVisible = false;
//         }
//       }
//       return settings;
//     },

//     save: function(settings) {
//       sessionStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
//     }
//   };

//   if (!Object.keys(store.fetch()).length) {
//     store.save({
//       isHidden: false,
//       isCollapsed: true,
//       showButtonText: 'Скрыть',
//       expandButtonText: 'Показать'
//     });
//   }

//   new Vue({
//     el: '#collections-in-catalog',


//     data: {
//       settings: store.fetch()
//     },

//     ready: function() {
//       this.$watch('settings', function(settings) {
//         store.save(settings);
//       }, {
//         deep: true
//       });
//     },

//     methods: {
//       setCollectionsVisibility: function(ev) {
//         ev.preventDefault();

//         if (this.settings.isHidden) {
//           this.settings.showButtonText = 'Скрыть';
//         } else {
//           this.settings.showButtonText = 'Показать';
//         }

//         this.settings.isHidden = !this.settings.isHidden;
//       },

//       setCollectionsState: function(ev) {
//         ev.preventDefault();

//         if (this.settings.isCollapsed) {
//           this.settings.expandButtonText = 'Скрыть';
//         } else {
//           this.settings.expandButtonText = 'Показать';
//         }

//         this.settings.isCollapsed = !this.settings.isCollapsed;
//       }
//     }
//   });

//   $(window).on('load', function() {
//     $(document).on('_ready yiiListViewUpdated', function() {
//       if ($('.collection.is-active').length) $(window).scrollTo($('.hide-menus-button'), 750);
//     }).trigger('_ready');
//   });
// })();

},{}],4:[function(require,module,exports){
'use strict';

var $compareTable = $('.js-compare-table');

if ($compareTable.length) {

  var productWidth = $('.compare-table__param').width();

  $(document).on('click', '.js-compare-prev', function () {
    $compareTable = $('.js-compare-table');

    if ($compareTable.hasClass('animated')) return;
    if ($compareTable[0].scrollLeft <= 0) return;
    $compareTable.addClass('animated');
    $compareTable.animate({ scrollLeft: '-=' + productWidth }, 250, function () {
      $compareTable.removeClass('animated');
    });
  });

  $(document).on('click', '.js-compare-next', function () {
    $compareTable = $('.js-compare-table');
    var $compareTableList = $compareTable.find('table:first');

    if ($compareTable.hasClass('animated')) return;
    if ($compareTable[0].scrollLeft + productWidth * 2 > $compareTableList.width()) return;
    $compareTable.addClass('animated');
    $compareTable.animate({ scrollLeft: '+=' + productWidth }, 250, function () {
      $compareTable.removeClass('animated');
    });
  });
}

},{}],5:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * need imagesLoaded plugin to be included in vendor.js
 *
 * markup example:
 *
 * <section class="js-decorator" data-decorator-settings='{"defaultInterior": 1, "wallpapers": "$img"}'>
 *   <select class="js-decorator-select">
 *     <option value="1"></option>
 *   </select>
 *   <div class="js-decorator-wall"></div>
 *   <div class="js-decorator-overlay"></div>
 * </section>
 *
 * initialization example:
 *
 * const decorator = new Decorator();
 *
 */

var Decorator = function () {
  function Decorator() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$selector = _ref.selector,
        selector = _ref$selector === undefined ? '.js-decorator' : _ref$selector,
        _ref$wallSelector = _ref.wallSelector,
        wallSelector = _ref$wallSelector === undefined ? '.js-decorator-wall' : _ref$wallSelector,
        _ref$overlaySelector = _ref.overlaySelector,
        overlaySelector = _ref$overlaySelector === undefined ? '.js-decorator-overlay' : _ref$overlaySelector,
        _ref$interiorsListSel = _ref.interiorsListSelector,
        interiorsListSelector = _ref$interiorsListSel === undefined ? '.js-decorator-select' : _ref$interiorsListSel,
        _ref$interiorsBackgro = _ref.interiorsBackgroundToggler,
        interiorsBackgroundToggler = _ref$interiorsBackgro === undefined ? '.js-toggle-interior' : _ref$interiorsBackgro,
        _ref$draggablePattern = _ref.draggablePatternSelector,
        draggablePatternSelector = _ref$draggablePattern === undefined ? '.js-draggable-pattern' : _ref$draggablePattern,
        _ref$dropzoneSelector = _ref.dropzoneSelector,
        dropzoneSelector = _ref$dropzoneSelector === undefined ? '.js-decorator-dropzone' : _ref$dropzoneSelector,
        _ref$resetSelector = _ref.resetSelector,
        resetSelector = _ref$resetSelector === undefined ? '.js-reset-decorator' : _ref$resetSelector,
        _ref$readyClass = _ref.readyClass,
        readyClass = _ref$readyClass === undefined ? 'is-ready' : _ref$readyClass,
        _ref$readyAnimationDu = _ref.readyAnimationDuration,
        readyAnimationDuration = _ref$readyAnimationDu === undefined ? 500 : _ref$readyAnimationDu;

    _classCallCheck(this, Decorator);

    this.$element = $(selector);

    if (!this.$element.length) {
      /* eslint-disable */
      console.warn('can\'t find ' + this.settings.selector + ' element on page');
      /* eslint-enable */
      return;
    }

    this.settings = _extends({
      selector: selector,
      wallSelector: wallSelector,
      overlaySelector: overlaySelector,
      interiorsListSelector: interiorsListSelector,
      interiorsBackgroundToggler: interiorsBackgroundToggler,
      draggablePatternSelector: draggablePatternSelector,
      dropzoneSelector: dropzoneSelector,
      resetSelector: resetSelector,
      readyClass: readyClass,
      readyAnimationDuration: readyAnimationDuration
    }, this.$element.data('decoratorSettings'));

    this.$walls = this.$element.find(this.settings.wallSelector);

    this.init();
  }

  _createClass(Decorator, [{
    key: 'init',
    value: function init() {
      var _this = this;

      this.setInterior(this.settings.defaultInterior).setWallpapers(this.settings.wallpapers).imagesLoaded().then(function () {
        var self = _this;

        _this.toggleReadyState().then(function () {
          $(_this.settings.interiorsListSelector).on('change', function changeHandler() {
            self.changeHandler($(this).val());
          });

          $(_this.settings.interiorsBackgroundToggler).on('click', function clickHandler() {
            self.changeHandler($(this).data('value'));
            $(this).addClass('is-active').siblings().removeClass('is-active');
          });

          _this.initDragndrop();

          $(_this.settings.resetSelector + ':first').click(function () {
            return _this.resetWallpapers();
          });
        });
      });
    }
  }, {
    key: 'changeHandler',
    value: function changeHandler(id) {
      var _this2 = this;

      this.toggleReadyState().then(function () {
        _this2.setInterior(id).imagesLoaded().then(function () {
          _this2.toggleReadyState();
        });
      });

      return this;
    }
  }, {
    key: 'imagesLoaded',
    value: function imagesLoaded() {
      var _this3 = this;

      return new Promise(function (resolve) {
        _this3.$element.imagesLoaded({
          background: _this3.settings.wallSelector + ', ' + _this3.settings.overlaySelector
        }).done(function () {
          resolve();
        });
      });
    }
  }, {
    key: 'initDragndrop',
    value: function initDragndrop() {
      var self = this;

      $('*[draggable!=true]', '.slick-track').unbind('dragstart');

      $(this.settings.draggablePatternSelector).draggable({
        revert: true
      }).on('draggable mouseenter mousedown', function (e) {
        e.stopPropagation();
      });

      $(this.settings.dropzoneSelector).droppable({
        accept: this.settings.draggablePatternSelector,
        over: function over(e) {
          var $wall = $($(e.target).data('target'));
          $wall.addClass('is-drag-hovered');
        },
        out: function out(e) {
          var $wall = $($(e.target).data('target'));
          $wall.removeClass('is-drag-hovered');
        },
        drop: function drop(e, ui) {
          var $wall = $($(e.target).data('target'));
          var src = $(ui.draggable[0]).data('src');
          $wall.css('background-image', 'url(\'' + src + '\')').removeClass('is-drag-hovered');
          $(self.settings.resetSelector).addClass('is-active');
        }
      });
    }
  }, {
    key: 'setInterior',
    value: function setInterior(id) {
      this.$element.attr('data-active-interior', id);

      return this;
    }
  }, {
    key: 'setWallpapers',
    value: function setWallpapers(src) {
      this.$walls.css('background-image', 'url(\'' + src + '\')');

      return this;
    }
  }, {
    key: 'resetWallpapers',
    value: function resetWallpapers() {
      this.$walls.css('background-image', 'url(\'' + this.settings.wallpapers + '\')');
      $(this.settings.resetSelector).removeClass('is-active');

      return this;
    }
  }, {
    key: 'toggleReadyState',
    value: function toggleReadyState() {
      var _this4 = this;

      this.$element.toggleClass(this.settings.readyClass);

      return new Promise(function (resolve) {
        setTimeout(function () {
          return resolve();
        }, _this4.readyAnimationDuration);
      });
    }
  }]);

  return Decorator;
}();

$(function () {
  if (!$('.js-decorator').length) return;
  /* eslint-disable */
  var decorator = new Decorator();
  /* eslint-enable */
});

},{}],6:[function(require,module,exports){
'use strict';

/* global iro */

$(document).on('click', '.js-toggle-filter-items', function toggleListHandler(e) {
  e.preventDefault();
  $(this).closest('fieldset').find('[data-list-root]').toggleClass('is-expanded');
});

var colorPicker = void 0;
if ($('.js-color-picker').length) {
  $(document).on('initColorpicker yiiListViewUpdated', function () {
    initWidget();

    colorPicker.on('mount', function () {
      pickColorsFromListOnWheel();
    });

    colorPicker.on('input:end', function () {
      checkColorsFromWheelInList();
    });

    // $(document).on('click', '.IroWheel', (e) => {
    //   console.log($(e.target));
    //   if ($(e.target).prop('tagName') === 'use') return;
    //   colorPicker.addColor(colorPicker.colors[colorPicker.color.index]);
    // });

    $('.text-colors-list__item').click(function addColor() {
      colorPicker.addColor($(this).data('color'));
    });
  }).trigger('initColorpicker');
}

function initWidget() {
  colorPicker = new iro.ColorPicker('.js-color-picker', {
    width: 308,
    handleRadius: 16,
    handleSvg: '#picker-handle'
  });
}

function pickColorsFromListOnWheel() {
  var colorsList = [];
  $('.text-colors-list__item--active').each(function colorHandler() {
    if ($(this).data('color')) {
      colorsList.push($(this).data('color'));
    }
  });
  if (colorsList.length) {
    colorPicker.setColors(colorsList);
  }
}

function checkColorsFromWheelInList() {
  $('.text-colors-list__item--active').removeClass('text-colors-list__item--active');
  colorPicker.colors.forEach(function (color) {
    $('.text-colors-list__item[data-text-color="' + getColorName(color.rgb.r, color.rgb.g, color.rgb.b) + '"]').addClass('text-colors-list__item--active');
  });
}

function getColorName($r, $g, $b) {
  var colorNames = [[['black', 'blue', 'blue'], ['green', 'blue', 'blue'], ['green', 'green', 'turq']], [['red', 'red', 'violet'], ['brown', 'gray', 'blue'], ['green', 'green', 'turq']], [['red', 'pink', 'violet'], ['orange', 'pink', 'pink'], ['yellow', 'beige', 'white']]];

  var rAppx = Math.round($r / 128);
  var gAppx = Math.round($g / 128);
  var bAppx = Math.round($b / 128);

  return colorNames[rAppx][gAppx][bAppx];
}

},{}],7:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
  if ($('#calculator-app').length && window.initCalculatorApp) {
    window.initCalculatorApp();
  }
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Textarea symbol count
// -------------------

$('.js-textarea-counter').on('keyup paste change', function countLetters() {

  var $textarea = $(this);
  var $count = $textarea.closest('.form__field').find('.textarea-symbol-counter span');
  var $textlength = $textarea.val().length;

  if ($textarea.val().length > 200) {
    $count.closest('.textarea-symbol-counter').css({ color: 'red' });
  } else {
    $count.closest('.textarea-symbol-counter').css({ color: 'black' });
  }

  $count.text($textlength);
});

// Touched inputs state
// --------------------

$('.form__input, .form__textarea').on('focus', function focusHandler() {
  $(this).closest('.form__field').addClass('is-touched');
}).on('blur', function blurHandler() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

  $this.closest('.form__field').removeClass('is-touched');
}).on('inputs-init', function inputsInit() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
    $this.closest('.form__field').addClass('is-touched');
  }
}).trigger('inputs-init');

// Datepicker
// ----------

$('.js-datepicker').datepicker({
  dateFormat: 'dd.mm.yy'
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

// Filters

(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var openedClass = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(openedClass);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    if (_globals.$BODY.hasClass(openedClass)) {
      url = new URL(window.location);
      url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', url);
    } else {
      url = new URL(window.location);
      if (url.searchParams.get('filter')) {
        window.history.back();
      }
    }
  });

  $(document).on('click.close-mobile-filters', function (e) {
    var $target = $(e.target);
    if ($target.closest('.filter').length || $target.hasClass('js-toggle-mobile-filters')) return;
    if (_globals.$BODY.hasClass(openedClass)) {
      $('.js-toggle-mobile-filters').first().click();
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(openedClass);
    } else {
      _globals.$BODY.addClass(openedClass);
    }
  }

  $(document).on('click', '.js-toggle-filter-list', function toggleList(e) {
    e.preventDefault();
    $(this).closest('.js-filter-list').toggleClass('is-expanded');
  });
})();

$(function () {

  $('input[type="search"]').each(function showSearchClear() {
    var $searchInput = $(this);
    var $searchForm = $searchInput.closest('.js-search-form');
    var $clearBtn = $searchForm.find('[type="reset"]');
    var touchedClass = 'is-touched';

    if ($searchForm.length < 1) return;

    $searchInput.on('keyup paste change', function () {
      if ($searchInput.val() === '') {
        $searchInput.removeClass(touchedClass);
        return;
      }
      $searchInput.addClass(touchedClass);
    });

    $searchForm.on('reset', function () {
      setTimeout(function () {
        $searchInput.trigger('change');
      }, 0);
    });

    $clearBtn.on('click', function () {
      $searchInput.attr('value', '');
    });
  });

  $('.js-mobile-search .js-search-form input').on('click', function () {
    _globals.$BODY.addClass('search-is-opened');
  });

  $('.js-mobile-search-toggler').on('click', function () {
    _globals.$BODY.removeClass('search-is-opened');
  });
});

$(function () {
  $(document).on('click', '.js-header__search-button', function openSearch(e) {
    if ($(this).closest('.js-header__messengers-wrapper').hasClass('is-expanded')) {
      return;
    }

    e.preventDefault();
    $(this).closest('.js-header__messengers-wrapper').addClass('is-expanded');
  });

  $(document).on('click', '.js-close-search', function openSearch(e) {
    e.preventDefault();

    $(this).closest('.js-header__messengers-wrapper').removeClass('is-expanded');
  });
});

},{"./globals.js":8}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

// Trigger fonts loaded event
// --------------------------

if (document.fonts !== undefined) {
  document.fonts.onloadingdone = function fontsLoadedCallback() {
    $(document).trigger('fontsLoaded');
    $(window).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":1}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":8}],10:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer === '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],11:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;
  var lazyloadThrottleTimeout = void 0;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    return observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    if (!image.dataset.lazy) return;
    image.src = image.dataset.lazy;
    image.classList.remove('js-lazy-pic');
    setTimeout(function () {
      $(window).trigger('imageLoaded');
    }, 100);
  }

  function observerFallback() {
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length === 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated lazyUpdate', function () {
      addImagesToObserver();
    });
    $(document).ready(function () {
      addImagesToObserver();
    });
    $(window).on('load', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else if (completed) {
      observerFallback();
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],12:[function(require,module,exports){
'use strict';

(function () {

  function initializePhoneMasks() {
    $('input[type="tel"]').each(function initMask() {
      var $input = $(this);
      var defaultPlaceholder = $input.attr('placeholder') || '';
      var defaultPlaceholderMask = '+7 (___) ___-__-__';
      var phoneMask = '+7 (000) 000-00-00';
      var pasted = false;

      $input.on('focus', function () {
        $input.attr('placeholder', defaultPlaceholderMask);
      }).on('blur', function () {
        $input.attr('placeholder', defaultPlaceholder);
      });

      $input.on('paste drop', function () {
        var isEmpty = !$input.val();
        var element = $input.get(0);
        var selectionLength = element.selectionEnd - element.selectionStart;

        if (!isEmpty && $input.val().length !== selectionLength) {
          return;
        }

        pasted = true;
      });

      $input.on('input', function (e) {
        var value = $input.val();

        if (value.startsWith('8')) {
          if (pasted && value.length !== 11) {
            pasted = false;
            return;
          }

          pasted = false;

          e.stopPropagation();

          value = value.replace('8', '+7');
          $input.val(value);
        }
      });

      $input.mask(phoneMask);
    });
  }

  $(window).on('initializePhoneMasks', initializePhoneMasks).trigger('initializePhoneMasks');
})();

},{}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function mouseenterHandler() {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!hasDropdown && link) {
      window.location.href = $anchor.attr('href');
    } else if (isHovered && link) {
      window.location.href = link;
    } else {
      $parent.toggleClass('is-hovered');
      if ($parent.hasClass('is-hovered')) {
        $(document).on('click.close-menu-by-backdrop', function (event) {
          if (!$(event.target).closest('.js-menu').length) {
            $parent.removeClass('is-hovered');
            $(document).off('click.close-menu-by-backdrop');
          }
        });
      } else {
        $(document).off('click.close-menu-by-backdrop');
      }
    }
  });

  $('.js-toggle-dropdown').click(function toggleDropdown() {
    var $parent = $(this).parent();
    $parent.toggleClass('is-hovered').siblings().removeClass('is-hovered');
    if ($parent.hasClass('is-hovered')) {
      $(document).on('click.close-menu-by-backdrop', function (event) {
        if (!$(event.target).closest('.js-menu').length) {
          $parent.removeClass('is-hovered');
          $(document).off('click.close-menu-by-backdrop');
        }
      });
    } else {
      $(document).off('click.close-menu-by-backdrop');
    }
  });
}

(function () {
  $(document).on('click', '.js-toggle-info-menu', function (event) {
    event.preventDefault();

    if (_globals.$BODY.hasClass('catalogue-menu-is-opened')) {
      _globals.$BODY.removeClass('menu-is-opened').removeClass('catalogue-menu-is-opened'); // закрываем каталожное меню
    }
    _globals.$BODY.toggleClass('menu-is-opened info-menu-is-opened');
  });

  $(document).on('click', '.js-toggle-catalogue-menu', function (event) {
    event.preventDefault();

    if (_globals.$BODY.hasClass('info-menu-is-opened')) {
      _globals.$BODY.removeClass('menu-is-opened').removeClass('info-menu-is-opened'); // закрываем информационное меню
    }
    _globals.$BODY.toggleClass('menu-is-opened catalogue-menu-is-opened');
  });
})();

},{"./globals.js":8}],14:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.overlay-close', '.js-close-popup', function clickHandler(e) {
  e.preventDefault();

  $.overlayLoader(false, _extends({
    node: $(this).parent()
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').html(data);
      // setTimeout(() => {
      //   $(window).trigger('resize.uniloader');
      // }, 100);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
      $.overlayLoader(true, _extends({
        node: $node
      }, OVERLAY_LOADER_DEFAULTS));
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Coockie alert on homepage
_globals.$BODY.on('click', '.js-close-alert', function closeAlert(e) {
  e.preventDefault();
  var $parent = $(this).closest('.js-alert');

  $parent.removeClass('--shown');
  localStorage.setItem('shown', 'yes');
});

/*
// Вывод попапа в карточке товара (компаньоны)
$DOCUMENT.on('click', '.js-card-popup', function(event) {
  event.preventDefault();
  event.stopPropagation();
  const $this = $(this);
  const $popup = $($this.attr('href'));
  const $selectedElData = $this.data('image-id');

  $popup.toggleClass('is-active');

  $popup.find('.tabs-nav li a').each(function() {
    let $el = $(this).attr('href').split('#tab-').join('');

    if($el == $selectedElData) {
      $(this).click();
    }
  });

  if($WINDOW.width() <= MOBILE_WIDTH) {
    $('html, body').animate({
    scrollTop: $('#combination-popup').offset().top - $('.header').height() - 15
    }, 200, 'linear');
  }
});

$('.js-close-card-popup').click(function closePopup(e) {
  e.preventDefault();
  const $this = $(this);
  const $parent = $this.parent();

  $parent.removeClass('is-active');

  if($WINDOW.width() <= MOBILE_WIDTH && $('.card-page__variants')) {
    $('html, body').animate({
    scrollTop: $('.card-page__variants').offset().top - $('.header').height() - 15
    }, 200, 'linear');
  }
});
*/

$(function () {
  // Поиск цветов в попапе из карточки
  _globals.$DOCUMENT.on('keyup paste change', '.js-search', function colorSearch() {
    var $input = $(this);
    var $inpValue = $input.val();
    var $items = $('.js-search-area a');
    var $resetBtn = $input.siblings('.js-reset');

    if ($inpValue === '' || !$inpValue || $inpValue === undefined || $inpValue === null) {
      $resetBtn.removeClass('is-active');
    } else {
      $resetBtn.addClass('is-active');
    }

    $items.each(function includesFunc() {
      var $this = $(this);
      if (!$this.find('.card-page__variant-text').text().toLowerCase().includes($inpValue.toLowerCase())) {
        if (!$this.find('.js-color').data('color').includes($inpValue)) {
          $this.fadeOut();
        }
      } else {
        $this.fadeIn();
      }
    });

    $resetBtn.on('click', function resetSearch() {
      var $btn = $(this);

      $btn.removeClass('is-active');
      $btn.siblings('.js-search').val('').trigger('change');
    });
  });
});

$(function () {
  // Попап компаньонов на странице товара
  _globals.$DOCUMENT.on('click', '.js-card-popup', function setCompanion(e) {
    e.preventDefault();
    var $this = $(this);
    var $selectedElData = $this.data('image-id');

    $('.combination-popup').addClass('is-active');

    $('.combination-popup').find('.tabs-nav li a').each(function loopCompanion() {
      var $el = $(this).attr('href').split('#companion').join('');

      if (+$el.toString() === +$selectedElData) {
        $(this).click();

        $(this).closest('.tabs-nav').scrollTo($(this), {
          duration: 300,
          offset: -$(this).closest('.tabs-nav').width() / 3
        });
      }
    });

    if (_globals.$WINDOW.width() <= _globals.LANDSCAPE_MOBILE_WIDTH) {
      $('html, body').animate({
        scrollTop: $(window).scrollTop(0)
      }, 500, 'linear');
    }
  });

  _globals.$DOCUMENT.on('click', '.js-close-combination-popup', function (e) {
    e.preventDefault();
    $('.combination-popup').removeClass('is-active');
  });
});

},{"./globals.js":8}],15:[function(require,module,exports){
'use strict';

$('.js-print').click(function (e) {
  e.preventDefault();
  window.print();
});

},{}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  // const offset = $link.data('offset') || $('.header').length ? $('.header').height() : 0;
  var offset = $link.data('offset') || 0;

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
  var $goTopButton = $('<div class="go-top-btn"></div>');

  $goTopButton.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopButton.addClass('is-visible');
    } else {
      $goTopButton.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopButton);
}

(function () {
  var scrollPos = $(window).scrollTop();
  var scrollCheckingTimeout = void 0;

  _globals.$WINDOW.scroll(function () {
    if (scrollCheckingTimeout) {
      clearTimeout(scrollCheckingTimeout);
    }
    scrollCheckingTimeout = setTimeout(function () {
      return checkScrollPos();
    }, 0);
  });

  function checkScrollPos() {
    var newScrollPos = _globals.$WINDOW.scrollTop();
    if (newScrollPos > $('.header').height()) {
      _globals.$BODY.addClass('scrolledFromTop');
      $('.js-menu li').removeClass('is-hovered'); // refs #72387
      if (newScrollPos > scrollPos) {
        _globals.$BODY.addClass('scrolledDown').removeClass('scrolledUp');
      } else if (newScrollPos < scrollPos) {
        _globals.$BODY.addClass('scrolledUp').removeClass('scrolledDown');
      }
    } else {
      _globals.$BODY.removeClass('scrolledFromTop scrolledDown');
    }
    scrollPos = newScrollPos;
  }
})();

},{"./globals.js":8,"./helpers.js":9}],17:[function(require,module,exports){
'use strict';

(function () {
  var $seenPanel = $('.js-seen-panel');
  var $products = $seenPanel.find('.js-product');

  if (!$seenPanel.length || !$products.length) return;

  $seenPanel.show();

  $('.js-toggle-seen-panel').click(function () {
    $seenPanel.toggleClass('is-expanded');
  });
})();

},{}],18:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

(function () {

  if (window.matchMedia && window.matchMedia('print').matches) {
    return;
  }

  $('.js-slideshow').each(function createSlider() {
    var $this = $(this);

    $this.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      var $nextSlide = $(slick.$slides.get(nextSlide));
      var $slideSoureSets = $nextSlide.find('source');
      $($slideSoureSets).each(function setSourceset() {
        var $source = $(this);
        if (!$source.attr('srcset')) {
          $source.attr('srcset', $source.data('lazy'));
        }
      });
    });

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: true,
      pauseOnHover: true,
      lazyLoad: 'ondemand',
      responsive: [{
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1,
          sliderToScroll: 1,
          arrows: false
        }
      }]
    });
  });

  $(document).on('vitrine-gallery-init tab-changed', function () {
    initVitrineGallery();
  }).trigger('vitrine-gallery-init');

  function initVitrineGallery() {
    $('.js-vitrine-slider').each(function createSlider() {
      var $this = $(this);
      var $controls = $this.closest('[data-slider-root]').find('.js-vitrine-slider-controls');

      if ($this.hasClass('slick-initialized')) {
        if ($this.find('.slick-slide').width() > 1) {
          return;
        }
        $this.slick('unslick');
      }

      $this.on('init afterChange', function () {
        var $scroll = $controls.find('.slick-dots');
        if (!$scroll.length) return;

        if ($scroll.width() > $(window).width() - 40) {
          $controls.addClass('with-scroller');
        }

        $scroll.scrollTo($controls.find('.slick-active'), {
          duration: 300,
          offset: -$controls.width() / 2
        });
        setTimeout(function () {
          if ($scroll.scrollLeft() === 0) {
            $controls.addClass('is-at-start');
          } else {
            $controls.removeClass('is-at-start');
          }
          if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
            $controls.addClass('is-at-end');
          } else {
            $controls.removeClass('is-at-end');
          }
        }, 200);
      });

      $this.slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        arrows: true,
        dots: false, // $(window).width() >= 896 ? !$controls.length : true
        appendDots: $controls,
        appendArrows: $controls,
        responsive: [{
          breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }, {
          breakpoint: 896 + 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true
          }
        }, {
          breakpoint: 700 + 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            rows: 1
          }
        }]
      });
    });
  }

  $('.js-gallery-slider').each(function createSlider() {
    var $this = $(this);
    var $parent = $this.parent();

    $this.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }]
    });
  });

  $('.js-designers-slider').each(function createSlider() {
    var $this = $(this);
    var $controls = $this.closest('[data-slider-root]').find('.js-designers-slider-controls');

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() > $(window).width() - 40) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 2.5,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $controls,
      dots: true,
      appendDots: $controls,
      touchThreshold: 10,
      responsive: [{
        breakpoint: 896 + 1,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }]
    });
  });

  $('.js-brands-slider').each(function createSlider() {
    var $this = $(this);

    $this.slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      responsive: [{
        breakpoint: _globals.NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: 'unslick'
      }]
    });
  });

  $('.js-reviews-slider').each(function createSlider() {
    var $this = $(this);
    var $parent = $this.parent();
    var $controls = $parent.find('.js-reviews-slider-controls');

    if ($this.hasClass('slick-initialized')) {
      if ($this.find('.slick-slide').width() > 1) {
        return;
      }
      $this.slick('unslick');
    }

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;

      if ($scroll.width() > $(window).width() - 40) {
        $controls.addClass('with-scroller');
      }

      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      arrows: true,
      dots: false, // $(window).width() > MOBILE_WIDTH ? !$controls.length : true
      appendArrows: $controls,
      appendDots: $controls,
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }]
    });
  });

  /* refs #75700
  $(() => {
    $(document).on('init-collections-slider yiiListViewUpdated', () => {
      initCollectionsGallery();
    }).trigger('init-collections-slider');
      $('body').on('iasRender', () => { initCollectionsGallery(); });
      function initCollectionsGallery() {
      $('.js-collections-slider').each(function createSlider() {
        const $slider = $(this);
          $slider.slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
          responsive: [
            {
              breakpoint: TABLET_WIDTH + 1,
              settings: 'unslick',
            },
          ],
        });
      });
    }
   });
  */

  $(function () {
    $('.js-card-gallery-slider').each(function createSlider() {
      var $slider = $(this);
      var $parent = $slider.closest('[data-slider-root]');
      var $connected = $parent.find('.js-card-thumbs-slider');
      var $dots = $parent.find('.js-card-gallery-dots');

      if ($slider.hasClass('slick-initialized')) {
        if ($slider.find('.slick-slide').width() > 1) return;
        $slider.slick('unslick');
      }

      $slider.on('init afterChange', function () {
        var $scroll = $dots.find('.slick-dots');
        if (!$scroll.length) return;
        $scroll.scrollTo($dots.find('.slick-active'), {
          duration: 300,
          offset: -$dots.width() / 2
        });
        setTimeout(function () {
          if ($scroll.scrollLeft() === 0) {
            $dots.addClass('is-at-start');
          } else {
            $dots.removeClass('is-at-start');
          }
          if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
            $dots.addClass('is-at-end');
          } else {
            $dots.removeClass('is-at-end');
          }
        }, 200);
      });

      $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: false,
        lazyLoad: 'ondemand',
        asNavFor: $connected,
        fade: true,
        responsive: [{
          breakpoint: 1280 + 1,
          settings: {
            dots: true,
            appendDots: $dots,
            fade: false
          }
        }]
      });
    });

    $('.js-card-thumbs-slider').each(function createSlider() {
      var $slider = $(this);
      var $parent = $slider.closest('[data-slider-root]');
      var $connected = $parent.find('.js-card-gallery-slider');

      if ($slider.hasClass('slick-initialized')) {
        if ($slider.find('.slick-slide').width() > 1) return;
        $slider.slick('unslick');
      }

      $slider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        vertical: true,
        verticalSwiping: true,
        touchThreshold: 100,
        // appendDots: $dots,
        asNavFor: $connected,
        focusOnSelect: true,
        responsive: [{
          breakpoint: _globals.NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }, {
          breakpoint: 1280 + 1,
          settings: 'uslick'
        }]
      });
    });
  });

  $(function () {
    $('.js-variants-slider').each(function createSliderHandler() {
      createSlider(this);
    });

    function createSlider(slider) {
      var $slider = $(slider);
      var toShow = [5, 4, 3, 4];

      if ($slider.hasClass('slick-initialized')) {
        if ($slider.find('.slick-slide').width() > 1) return;
        $slider.slick('unslick');
      }

      $slider.slick({
        slidesToShow: toShow[0],
        slidesToScroll: toShow[0],
        infinite: false,
        arrows: true,
        dots: false,
        focusOnSelect: true,
        responsive: [{
          breakpoint: _globals.NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: toShow[1],
            slidesToScroll: toShow[1]
          }
        }, {
          breakpoint: 1280 + 1,
          settings: {
            slidesToShow: toShow[2],
            slidesToScroll: toShow[2]
          }
        }, {
          breakpoint: 896 + 1,
          settings: 'unslick'
        }]
      });
    }

    $('.js-item-color-el').click(function toggleActiveColor(e) {
      e.preventDefault();
      $(this).addClass('is-active').closest('.slick-slide').siblings().find('.js-item-color-el').removeClass('is-active');
    });

    // Функционал развертывания слайдера js-variants-slider в карточке
    // $('.js-toggle-variants-slider').click(function toggleSlider(e) {
    //   e.preventDefault();
    //   const $this = $(this);
    //   const $container = $this.closest('[data-slider-root]');
    //   const $slider = $container.find('.js-variants-slider');

    //   if ($container.hasClass('is-expanded')) {
    //     $slider.removeClass('no-slider');
    //     createSlider($slider);
    //   } else if ($slider.hasClass('slick-initialized')) {
    //     $slider.slick('unslick').addClass('no-slider');
    //   }

    //   $(document).trigger('lazyUpdate');
    //   $container.toggleClass('is-expanded');
    // });
  });

  $('.js-collection-gallery-slider').each(function createSlider() {
    var $this = $(this);

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true,
      arrows: false,
      // centerMode: true,
      variableWidth: true,
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }]
    });
  });

  $('.js-contact-gallery').each(function createSlider() {
    var $slider = $(this);
    var $parent = $slider.closest('[data-slider-root]');
    var $dots = $parent.find('.js-contact-gallery-dots');

    if ($slider.hasClass('slick-initialized')) {
      if ($slider.find('.slick-slide').width() > 1) return;
      $slider.slick('unslick');
    }

    $slider.on('init afterChange', function () {
      var $scroll = $dots.find('.slick-dots');
      if (!$scroll.length) return;
      $scroll.scrollTo($dots.find('.slick-active'), {
        duration: 300,
        offset: -$dots.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $dots.addClass('is-at-start');
        } else {
          $dots.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $dots.addClass('is-at-end');
        } else {
          $dots.removeClass('is-at-end');
        }
      }, 200);
    });

    $slider.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      touchThreshold: 100,
      infinite: false,
      dots: true,
      appendDots: $dots,
      arrows: false,
      variableWidth: true
    });
  });

  $('.js-info-gallery-slider').each(function createSlider() {
    var $slider = $(this);
    var $parent = $slider.closest('[data-slider-root]');
    var $dots = $parent.find('.js-info-gallery-dots');

    if ($slider.hasClass('slick-initialized')) {
      if ($slider.find('.slick-slide').width() > 1) return;
      $slider.slick('unslick');
    }

    $slider.on('init afterChange', function () {
      var $scroll = $dots.find('.slick-dots');
      if (!$scroll.length) return;
      $scroll.scrollTo($dots.find('.slick-active'), {
        duration: 300,
        offset: -$dots.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $dots.addClass('is-at-start');
        } else {
          $dots.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $dots.addClass('is-at-end');
        } else {
          $dots.removeClass('is-at-end');
        }
      }, 200);
    });

    $slider.slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      centerMode: true,
      dots: true,
      appendDots: $dots,
      arrows: false,
      variableWidth: true
      // responsive: [
      //   {
      //     breakpoint: TABLET_WIDTH + 1,
      //     settings: {
      //       slidesToShow: 3,
      //     },
      //   },
      // ],
    });
  });

  $('.js-interior-companions-slider').each(function createSlider() {
    var $slider = $(this);
    $slider.slick({
      slidesToShow: 7,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      arrows: true,
      responsive: [{
        breakpoint: _globals.NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 6
        }
      }, {
        breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 7
        }
      }, {
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 3,
          sliderToScroll: 3,
          arrows: false,
          dots: true
        }
      }]
    });
  });

  $('.js-half-slider').each(function createSlider() {
    var $slider = $(this);
    var $parent = $slider.closest('[data-slider-root]');
    var $controls = $parent.find('.js-half-slider-controls');

    $slider.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;
      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      dots: false,
      adaptiveHeight: true,
      appendArrows: $controls,
      touchThreshold: 100,
      responsive: [{
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          dots: true,
          arrows: false,
          appendDots: $controls
        }
      }]
    });
  });

  $('.js-photos-gallery').each(function createSlider() {
    var $this = $(this);
    var $parent = $this.closest('[data-slider-root]');
    var $controls = $parent.find('.js-photos-gallery-controls');
    var $status = $parent.find('.js-photos-gallery-pager');

    $this.on('init reInit beforeChange', function (event, slick, currentSlide, nextSlide) {
      var i = (nextSlide || 0) + 1;
      var count = '' + slick.slideCount;

      $status.html(i + '/' + count);
    });

    $this.on('init afterChange', function () {
      var $scroll = $controls.find('.slick-dots');
      if (!$scroll.length) return;
      $scroll.scrollTo($controls.find('.slick-active'), {
        duration: 300,
        offset: -$controls.width() / 2
      });
      setTimeout(function () {
        if ($scroll.scrollLeft() === 0) {
          $controls.addClass('is-at-start');
        } else {
          $controls.removeClass('is-at-start');
        }
        if (parseInt($scroll.scrollLeft(), 10) === parseInt($scroll[0].scrollWidth - $scroll.width(), 10)) {
          $controls.addClass('is-at-end');
        } else {
          $controls.removeClass('is-at-end');
        }
      }, 200);
    });

    $this.slick({
      slidesToShow: $this.find('img').length > 3 ? 3 : 2,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      arrows: true,
      appendArrows: $controls,
      centerMode: $this.find('img').length > 3,
      variableWidth: true,
      touchThreshold: 100,
      responsive: [{
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          dots: true,
          appendDots: $controls,
          arrows: false
        }
      }]
    });
  });

  $('.js-companions-slider').each(function createSlider() {
    var $this = $(this);
    // const $parent = $this.closest('[data-slider-root]');

    $this.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      arrows: true,
      draggable: false,
      responsive: [{
        breakpoint: 1700 + 1,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 1024 + 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 896 + 1,
        settings: 'unslick'
      }]
    });
  });
})();

},{"./globals.js":8}],19:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (isKeyToSkip(e.keyCode, e.ctrlKey)) {
    return e.keyCode;
  }
  if (isNotADigitKey(e.keyCode)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

function isKeyToSkip(keyCode, ctrlKey) {
  return isDelKey(keyCode) || isBackspaceKey(keyCode) || isTabKey(keyCode) || isEscKey(keyCode) || isSelectAllHotkeys(keyCode, ctrlKey) || isPositioningKey(keyCode) || isEnterKey(keyCode);
}

function isDelKey(keyCode) {
  return keyCode === 46;
}

function isBackspaceKey(keyCode) {
  return keyCode === 8;
}

function isTabKey(keyCode) {
  return keyCode === 9;
}

function isEscKey(keyCode) {
  return keyCode === 27;
}

function isEnterKey(keyCode) {
  return keyCode === 13;
}

function isSelectAllHotkeys(keyCode, ctrlKey) {
  // Ctrl+A
  return keyCode === 65 && ctrlKey === true;
}

function isPositioningKey(keyCode) {
  // arrows or home / end
  return keyCode >= 35 && keyCode <= 39;
}

function isNotADigitKey(keyCode) {
  // including numpad keys
  return (keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105);
}

},{"./globals.js":8}],20:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize fontsLoaded', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler(e) {
    e.preventDefault();
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if ($this.hasClass('js-close-siblings')) {
      $spoiler.siblings('.js-spoiler').removeClass('is-expanded');
    }
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler() {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $('.js-toggle-menu-items').click(function toggleMenuItems(e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var $parent = $this.parent().parent();

    $parent.toggleClass('is-expanded');
  });

  $('.js-toggle-collections').each(function toggleCollections() {
    var $toggler = $(this);
    var $parent = $toggler.parent();
    var childrenCount = $parent.children().length;
    var countPerRow = _helpers.IS_MOBILE_WIDTH ? 4 : 6;

    if (childrenCount < countPerRow) {
      $toggler.hide();
    }
  });

  _globals.$DOCUMENT.on('click', '.js-toggle-collections', function (event) {
    event.preventDefault();

    $(event.target).parent().toggleClass('is-expanded');
  });
});

},{"./globals.js":8,"./helpers.js":9}],21:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn(function () {
    return $(document).trigger('tab-changed');
  }).addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function handler() {
    if ($(this).hasClass('card-page__tabs-container')) {
      $(this).find('.tabs-nav li:last a').click();
    } else {
      $(this).find('.tabs-nav li:first a').click();
    }
  });
});

},{}],22:[function(require,module,exports){
'use strict';

/* global Vue */
/* eslint-disable */
window.initCalculatorApp = function () {
  if (!$('#calculator-app').length) return false;

  var calculatorApp = new Vue({
    el: '#calculator-app',
    directives: {
      init: {
        bind: function bind(el, binding, vnode) {
          vnode.context[binding.arg] = binding.value;
        }
      }
    },
    data: function data() {
      return {
        priceFor: null,
        width: null,
        depth: null,
        height: null,
        typeofcalc: null
      };
    },

    computed: {
      isRoomCalc: function isRoomCalc() {
        return this.typeofcalc === 'room';
      },
      isWallCalc: function isWallCalc() {
        return this.typeofcalc === 'wall';
      },
      area: function area() {
        if (this.typeofcalc === 'room') {
          return (this.width + this.depth) * 2 * this.height;
        }
        if (this.typeofcalc === 'wall') {
          return this.width * this.height;
        }
        return null;
      }
    },
    mounted: function mounted() {
      var busy = false;
      var $calculator = $('#area-calculator');

      if ($('input[name="vitrine-price-for"][value="item"]').closest('label').hasClass('is-checked')) $calculator.removeClass('is-active');

      $(document).on('click', '.js-toggle-price-type', function changeHandler() {
        if (busy) return;
        if ($(this).find('input').val() === 'area') {
          busy = true;
          $calculator.addClass('is-active');
          setTimeout(function () {
            busy = false;
          }, 100);
        } else {
          $calculator.removeClass('is-active');
          calculatorApp.setPricesForRolls();
        }
      });
    },

    methods: {
      submitCalculator: function submitCalculator() {
        if (this.area) {
          $('.area-calculator-trigger').removeClass('is-active');
          $('.js-product').each(function productHandler() {
            calculatorApp.setAreaPrice($(this));
          });
          $.post(document.location.hash, {
            roomSize: {
              areaOf: this.typeofcalc, depth: this.depth, width: this.width, height: this.height
            }
          });
        }
      },
      rollArea: function rollArea(width, height) {
        return width * height;
      },
      rollsAmount: function rollsAmount(area, rollArea) {
        return Math.ceil(area / rollArea, 10);
      },
      areaPrice: function areaPrice(rollsAmount, price) {
        return rollsAmount * price;
      },
      setAreaPrice: function setAreaPrice($product) {
        var $oldPrice = $product.find('.js-old-price');
        var oldPrice = $oldPrice.length ? parseFloat($oldPrice.data('price')) : null;
        var $price = $product.find('.js-price');
        var price = parseFloat($price.data('price'));
        var $currency = $price.find('.currency');
        var $amount = $product.find('.js-amount');
        var $amountInput = $product.find('.js-amount-input');
        var rollWidth = $product.data('rollWidth');
        var rollHeight = $product.data('rollHeight');
        var unitForms = $product.data('rollUnit');
        var rollsAmount = this.rollsAmount(this.area, this.rollArea(rollWidth, rollHeight));
        var areaPriceOld = this.areaPrice(rollsAmount, oldPrice);
        var areaPrice = this.areaPrice(rollsAmount, price);
        var units = this.pluralize(rollsAmount, unitForms);

        if ($oldPrice.length) {
          $oldPrice.html(areaPriceOld.toLocaleString('ru') + ' ' + $currency.prop('outerHTML'));
        }
        $price.html(areaPrice.toLocaleString('ru') + ' ' + $currency.prop('outerHTML'));
        $amount.html('\u0437\u0430 ' + rollsAmount + ' ' + units);
        $amountInput.val(rollsAmount).trigger('change');
      },
      setRollPrice: function setRollPrice($product) {
        var $oldPrice = $product.find('.js-old-price');
        var oldPrice = $oldPrice.length ? parseFloat($oldPrice.data('price')) : null;
        var $price = $product.find('.js-price');
        var price = parseFloat($price.data('price'));
        var $currency = $price.find('.currency');
        var $amount = $product.find('.js-amount');
        var $amountInput = $product.find('.js-amount-input');
        var unitForms = $product.data('rollUnit');
        var units = this.pluralize(1, unitForms);

        if ($oldPrice.length) {
          $oldPrice.html(oldPrice.toLocaleString('ru') + ' ' + $currency.prop('outerHTML'));
        }
        $price.html(price.toLocaleString('ru') + ' ' + $currency.prop('outerHTML'));
        $amount.html('\u0437\u0430 1 ' + units);
        $amountInput.val(1).trigger('change');
      },
      setPricesForRolls: function setPricesForRolls() {
        $('.js-product').each(function productHandler() {
          calculatorApp.setRollPrice($(this));
        });

        $.post(document.location.hash, { roomSize: { areaOf: null } });
      },
      pluralize: function pluralize(count, unitForms) {
        if (unitForms === undefined) return 'шт.';

        var cases = [2, 0, 1, 1, 1, 2];
        return unitForms[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
      }
    }
  });

  return calculatorApp;
};

window.initCalculatorApp();
/* eslint-enable */

},{}],23:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListRadioComponent = require('./components/OffersListRadioComponent.js');

var _OffersListRadioComponent2 = _interopRequireDefault(_OffersListRadioComponent);

var _OffersListSelectComponent = require('./components/OffersListSelectComponent.js');

var _OffersListSelectComponent2 = _interopRequireDefault(_OffersListSelectComponent);

var _OffersParamComponent = require('./components/OffersParamComponent.js');

var _OffersParamComponent2 = _interopRequireDefault(_OffersParamComponent);

var _OffersImageComponent = require('./components/OffersImageComponent.js');

var _OffersImageComponent2 = _interopRequireDefault(_OffersImageComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initCardApp = function () {
  if (!$('#js-card-app').length) return false;

  var cardPriceApp = new Vue({
    el: '#js-card-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-radio': _OffersListRadioComponent2.default,
      'offers-list-select': _OffersListSelectComponent2.default,
      'offers-param': _OffersParamComponent2.default,
      'offers-image': _OffersImageComponent2.default
    },
    data: function data() {
      return {
        offerInner: {},
        targetButton: ''
      };
    },

    computed: {
      offer: {
        get: function get() {
          return this.offerInner;
        },
        set: function set(offerData) {
          this.offerInner = offerData;
          this.$emit('changeOffer', this.offerInner);
          this.updateBasketButton();
        }
      }
    },
    mounted: function mounted() {
      this.$nextTick(function nextTickHandler() {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        this.$emit('setDefaultOffer');
      });
    },

    methods: {
      updateBasketButton: function updateBasketButton() {
        // Обновляем данные на кнопке купить
        var data = $(this.targetButton).data();
        $.extend(data, this.offerInner.basketData);
        $(this.targetButton).data(data);
      }
    }
  });

  return cardPriceApp;
}; /* global Vue */

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-app" class="product-card__controls-column"
 *   data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */


window.initCardApp();

},{"./components/OffersImageComponent.js":24,"./components/OffersListRadioComponent.js":25,"./components/OffersListSelectComponent.js":26,"./components/OffersParamComponent.js":27,"./components/PriceComponent.js":28}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersImage = {
  methods: {
    initImage: function initImage() {
      // console.log('Нужно обновить картинку!');
    },
    getMainImage: function getMainImage() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer, 'images')) return null;

      if (!Object.keys(this.$root.offer.images).length) return null;

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer.images, 'main')) return null;

      if (!Object.keys(this.$root.offer.images.main).length) return null;

      return this.$root.offer.images.main[0][type].src;
    },
    getMainImageWidth: function getMainImageWidth() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].width;
    },
    getMainImageHeight: function getMainImageHeight() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].height;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function () {
      _this.initImage();
    });
  }
};

exports.default = OffersImage;

},{}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListRadio = {
  props: {
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id === id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) {
        classArray.push(this.selectedClass);
      }

      if (offer.disabled) {
        classArray.push(this.disabledClass);
      }

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListRadio;

},{}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @type {{props: {offersList: {type: ArrayConstructor}}}}
 */
var OffersListSelect = {
  data: function data() {
    return {
      selectedId: null
    };
  },

  props: {
    offersList: {
      type: Array
    }
  },
  methods: {
    setOfferById: function setOfferById(id) {
      var _this = this;

      Object.keys(this.offersList).forEach(function (i) {
        if (_this.offersList[i].id === id) {
          _this.$root.offer = _this.offersList[i];
          return false;
        }
        return true;
      });
    },
    initSelectric: function initSelectric() {
      $(this.$el).find('select.no-default-selectric').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      }).on('change', function changeHandler() {
        $(this).trigger('input');
      });
    }
  },
  watch: {
    selectedId: function selectedId(offerId) {
      this.setOfferById(offerId);
    }
  },

  mounted: function mounted() {
    var _this2 = this;

    // Устанавливаем значение по умолчанию
    this.$root.$on('setDefaultOffer', function () {
      _this2.selectedId = _this2.$root.offer.id;
      // this.initSelectric();
    });

    this.$nextTick(function () {
      // this.initSelectric();
    });
  }
};

exports.default = OffersListSelect;

},{}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersParam = {
  data: function data() {
    return {
      param: {}
    };
  },

  methods: {
    initParams: function initParams(offer) {
      var outParam = {};

      Object.keys(offer.parameters).forEach(function (i) {
        var param = offer.parameters[i];
        if (param.keys) {
          Object.keys(param.keys).forEach(function (j) {
            outParam[j] = {
              id: param.id,
              name: param.name,
              value: param.value
            };
          });
        } else {
          outParam[param.id] = {
            id: param.id,
            name: param.name,
            value: param.value
          };
        }
      });

      this.param = outParam;
      // this.$root.$data.param = outParam;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function (offer) {
      _this.initParams(offer);
    });
  },


  template: '<div></div>'
};

exports.default = OffersParam;

},{}],28:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 */

/* eslint-disable */
/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
/* eslint-enable */
var Price = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString('ru-RU') + this.currencySuffix;
      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString('ru-RU') + this.currencySuffix;
      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = Price;

},{}],29:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable */
var CartSharing = {
  data: function data() {
    return {
      copied: false,
      url: null
    };
  },

  props: {
    defaultUrl: String
  },
  methods: {
    selectInputContent: function selectInputContent(event) {
      $(event.target).select();
    },
    copyToClipboard: function copyToClipboard() {
      this._copyTextToClipboard(this.url);
    },
    _successHandler: function _successHandler() {
      this.copied = true;
    },
    _copyTextToClipboard: function _copyTextToClipboard(text) {
      var vm = this;
      if (!navigator.clipboard) {
        this._fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        vm._successHandler();
      }, function (err) {});
    },
    _fallbackCopyTextToClipboard: function _fallbackCopyTextToClipboard(text) {
      var vm = this;
      var textArea = document.createElement('textarea');
      textArea.value = text;
      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        if (successful) {
          vm._successHandler();
        }
      } catch (err) {}
      document.body.removeChild(textArea);
    }
  },
  created: function created() {
    this.url = this.defaultUrl;
  },
  mounted: function mounted() {
    var _this = this;

    $(document).on('afterReplaceWith', function () {
      _this.url = $('.js-cart-share-url').data('url');
    });
    $(document).on('click', '#basket-share-linck', function () {
      var vm = _this;
      $.post('/basket/get-share/', { 'refreshBasketShare': 1 }, function (resp, status, xhr) {
        vm.url = resp;
      });
    });
  }
};

exports.default = CartSharing;
/* eslint-enable */

},{}],30:[function(require,module,exports){
'use strict';

var _CartSharing = require('./components/CartSharing.js');

var _CartSharing2 = _interopRequireDefault(_CartSharing);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initOrderApp = function () {
  if (!$('#js-order-app').length) return false;

  var orderApp = new Vue({
    el: '#js-order-app',
    components: {
      'cart-sharing': _CartSharing2.default
    },
    data: {},
    mounted: function mounted() {
      this.$nextTick(function () {
        $(window).trigger('initializePhoneMasks');
      });
    }
  });

  return orderApp;
}; /* global Vue */

window.initOrderApp();

},{"./components/CartSharing.js":29}]},{},[2]);
